<template>
  <div class="tab-content mt-3">
    <Card>
      <template v-slot:body>
        <div class="table-info-regie">
          <div class="justify-between hr-horizontale ">
            <div class=" title">Nom</div>
            <span class="hr-verticale "></span>
            <div class="value">{{ RegieCordonnes.name }}</div>
          </div>

          <div class=" justify-between hr-horizontale ">
            <div class="  title">Téléphone</div>
            <span class="hr-verticale "></span>
            <div class="value">
              <EditableInput
                :editable="editable"
                champName="phone"
                :item="RegieCordonnes"
                :value="RegieCordonnes.phone"
                type="text"
                :defaultColor="true"
                :updateFunction="editRegieInfoForAdmin"
                className="editable-inputClass"
              />
            </div>
          </div>
          <div class=" justify-between hr-horizontale  ">
            <div class="  title">E-mail</div>
            <span class="hr-verticale "></span>
            <div class="value">
              <EditableInput
                :editable="editable"
                champName="email"
                :item="RegieCordonnes"
                :value="RegieCordonnes.email"
                type="email"
                :defaultColor="true"
                :updateFunction="editRegieInfoForAdmin"
                className="editable-inputClass"
              />
            </div>
          </div>
          <div class=" justify-between hr-horizontale  ">
            <div class="  title">Adresse</div>
            <span class="hr-verticale "></span>
            <div class="value">
              <EditableInput
                :editable="editable"
                champName="adress"
                :item="RegieCordonnes"
                :value="RegieCordonnes.adress"
                type="text"
                :defaultColor="true"
                :updateFunction="editRegieInfoForAdmin"
                className="editable-inputClass"
              />
            </div>
          </div>
          <div class=" justify-between  hr-horizontale ">
            <div class="  title">Code postal</div>
            <span class="hr-verticale "></span>
            <div class="value">
              <EditableInput
                :editable="editable"
                champName="code_postal"
                :item="RegieCordonnes"
                :value="RegieCordonnes.code_postal"
                type="text"
                :defaultColor="true"
                :updateFunction="editRegieInfoForAdmin"
                className="editable-inputClass"
              />
            </div>
          </div>
          <div class=" justify-between  display-f ">
            <div class="  title">Ville</div>
            <span class="hr-verticale "></span>
            <div class="value">
              <EditableInput
                :editable="editable"
                champName="ville"
                :item="RegieCordonnes"
                :value="RegieCordonnes.ville"
                type="text"
                :defaultColor="true"
                :updateFunction="editRegieInfoForAdmin"
                className="editable-inputClass"
              />
            </div>
          </div>
        </div> </template
    ></Card>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
export default {
  props: {
    RegieCordonnes: {},
    editable: { default: true }
  },
  methods: {
    ...mapActions(['editRegieInfoForAdmin'])
  },
  components: {
    Card: () => import('../component/card'),
    EditableInput: () => import('../component/EditableInput')
  }
};
</script>

<style lang="scss" scoped>
.tab-content {
  font-size: 11px;
  .table-info-regie {
    border: 1px solid #b5b5b540;
  }
  .hr-horizontale {
    display: flex;
    border-bottom: 1px solid #b5b5b540;
  }
  .display-f {
    display: flex;
  }
  .hr-verticale {
    border-right: 1px solid #b5b5b540;
  }
  .title {
    padding-top: 2px;
    padding-bottom: 2px;
    width: 30%;
    text-align: right;
    color: #2a2a2a;
    font-family: 'Montserrat', sans-serif;
    font-size: 13px;
    padding-right: 19px;
    font-weight: 600;
  }
  .value {
    padding-top: 2px;
    padding-bottom: 2px;
    color: #5d5d5d;
    font-family: 'Montserrat', sans-serif;
    font-size: 13px;
    font-weight: 400;
    width: 70%;
    text-align: left;
    padding-left: 19px;
  }
}
</style>
